import React, { useState } from 'react';
import { getImageSrc } from '../../util';

type Props = {};

function CompanyMenuNav(props: { onClick: () => void; hidden: boolean }) {
  return (
    <div className="bg-gray-100 group relative" onMouseLeave={props.onClick}>
      <button
        type="button"
        onMouseEnter={() => {
          if (props.hidden) {
            props.onClick();
          }
        }}
        className="cursor-default group bg-gray-100 rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none"
      >
        <span>Company</span>
        <svg
          className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {/*Detailed Company Menu*/}
      <div
        hidden={props.hidden}
        className="transition duration-500 ease-out transform scale-100 absolute z-20 -ml-8 mr-8 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/4"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden w-1/2 mt-3">
          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            <div className="grid gap-4 grid-cols-2">
              <a
                href="/about/"
                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-primary-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                  />
                </svg>
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">
                    About Us
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Learn more about our company and story.
                  </p>
                </div>
              </a>

              <a
                href="https://blog.tabletab.io"
                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-primary-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                  />
                </svg>
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">Blog</p>
                  <p className="mt-1 text-sm text-gray-500">
                    Answers, advice, and stories just for you.
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
            <div className="flow-root">
              <a
                href="/refer-restaurants"
                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-primary-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                  />
                </svg>
                <span className="ml-3">Refer a Restaurant</span>
              </a>
            </div>

            <div className="flow-root">
              <a
                href="/contact"
                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-primary-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span className="ml-3">Contact Sales</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function UseCasesDropMenu(props: { onClick: () => void; hidden: boolean }) {
  return (
    <div className="bg-gray-100 group relative" onMouseLeave={props.onClick}>
      <button
        type="button"
        onMouseEnter={() => {
          if (props.hidden) {
            props.onClick();
          }
        }}
        className="cursor-default group bg-gray-100 rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none"
      >
        <span>Use Cases</span>
        <svg
          className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {/*Detailed Use Cases Menu*/}
      <div
        hidden={props.hidden}
        className="transition duration-500 ease-out transform scale-100 absolute z-20 -ml-8 mr-8 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/4"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden w-1/2 mt-3">
          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            <div className="grid gap-4 grid-cols-2">
              <a
                href="/use-cases/fsr"
                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-primary-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">
                    Contactless Dining
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Learn how TableTab can seamlessly integrate into your FSR
                    workflow.
                  </p>
                </div>
              </a>

              <a
                href="https://get.tabletab.io/menus?ref=website-nav-bar"
                target={'_blank'}
                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-primary-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">
                    Digital Menus
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Migrate from PDF menus to a beautiful on-premise digital
                    menu
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Bar: React.FC = () => {
  const [closeMobileMenu, setCloseMobileMenu] = useState(true);
  const [closeCompanyMenu, setCloseCompanyMenu] = useState(true);
  const [closeUseCaseMenu, setCloseUseCaseMenu] = useState(true);

  return (
    <div className="relative bg-gray-100">
      <div className="max-w-7xl sm:pr-2 md:pr-4 lg:pr-8 xl:pr-16 2xl:pr-20">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 lg:justify-start lg:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <span className="sr-only">TableTab</span>
              <picture>
                <source
                  srcSet={getImageSrc('TableTabCoral2_v2.webp')}
                  type={'image/webp'}
                />
                <source
                  srcSet={getImageSrc('TableTabCoral2_v2.png')}
                  type={'image/png'}
                />
                <img
                  className="lazyload w-auto h-10"
                  width={'90'}
                  height={'20'}
                  data-src="https://d2b8t2l9x5xm7h.cloudfront.net/website/TableTabCoral2_v2.webp"
                  alt="logo"
                />
              </picture>
            </a>
          </div>
          <div
            className="-mr-2 -my-2 lg:hidden"
            onClick={() => {
              setCloseMobileMenu(!closeMobileMenu);
            }}
          >
            <button
              type="button"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
            >
              <span className="sr-only">Open menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <nav className="hidden lg:flex space-x-10">
            <a
              href="/"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Home
            </a>
            <a
              href="/product"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Product
            </a>
            <UseCasesDropMenu
              onClick={() => setCloseUseCaseMenu(!closeUseCaseMenu)}
              hidden={closeUseCaseMenu}
            />
            <a
              href="/compare/pdfs"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Compare
            </a>
            <CompanyMenuNav
              onClick={() => setCloseCompanyMenu(!closeCompanyMenu)}
              hidden={closeCompanyMenu}
            />
          </nav>
          <div className="hidden lg:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="/contact"
              className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-primary-900"
            >
              Contact Us
            </a>
            <a
              href="https://portal.tabletab.io/signup?ref=nav-signup"
              target={'_blank'}
              rel="noopener"
              className="hover:text-white ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-primary-600 hover:bg-primary-700"
            >
              Get Started
            </a>
          </div>
        </div>
      </div>

      {/* mobile menu shown once clicked*/}
      <div
        className="absolute top-0 inset-x-0 p-2 z-20 transition transform origin-top-right lg:hidden"
        hidden={closeMobileMenu}
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <picture>
                  <source
                    srcSet={getImageSrc('TableTabCoral2_v2.webp')}
                    type={'image/webp'}
                  />
                  <source
                    srcSet={getImageSrc('TableTabCoral2_v2.png')}
                    type={'image/png'}
                  />
                  <img
                    className="lazyload w-auto h-10"
                    width={'90'}
                    height={'20'}
                    data-src="https://d2b8t2l9x5xm7h.cloudfront.net/website/TableTabCoral2_v2.webp"
                    alt="logo"
                  />
                </picture>
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  className={`bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500`}
                  onClick={() => {
                    setCloseMobileMenu(!closeMobileMenu);
                  }}
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-y-8">
                <a
                  href="/"
                  className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-primary-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    Home
                  </span>
                </a>
                <a
                  href="/product"
                  className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-primary-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                    />
                  </svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    Product
                  </span>
                </a>

                <a
                  href="/compare/pdfs"
                  className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-primary-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                    />
                  </svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    Compare
                  </span>
                </a>

                <a
                  href="/about"
                  className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-primary-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                    />
                  </svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    About Us
                  </span>
                </a>

                <a
                  href="https://blog.tabletab.io"
                  target={'_blank'}
                  rel="noopener"
                  className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-primary-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                    />
                  </svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    Blog
                  </span>
                </a>
              </nav>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
              <div>
                <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
                  Use Cases
                </h3>
                <ul className="mt-5 space-y-6">
                  <li className="flow-root">
                    <a
                      href="/use-cases/fsr"
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-primary-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                      </svg>
                      <span className="ml-3 text-base font-medium text-gray-900">
                        Contactless Dining
                      </span>
                    </a>
                  </li>
                  <li className="flow-root">
                    <a
                      href="https://get.tabletab.io/menus?ref=website-nav-bar"
                      target={'_blank'}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-primary-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="ml-3 text-base font-medium text-gray-900">
                        Digital Menus
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <a
                href="https://portal.tabletab.io/signup?ref=nav-signup"
                target={'_blank'}
                rel="noopener"
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary-600 hover:bg-primary-700 hover:text-white"
              >
                Sign up
              </a>
              <p className="mt-6 text-center text-base font-medium text-gray-500">
                Existing customer?
                <a
                  href="https://portal.tabletab.io"
                  className="text-primary-600 hover:text-primary-500"
                >
                  {' '}
                  Sign in
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bar;
