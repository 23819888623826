const env = process.env.REACT_APP_ENV;

let envApiUrl = '';
let envMenuUrl = '';
let envPaymentsUrl = '';
let envStripeKey = '';
let envPromotionsUrl = '';

if (env === 'production') {
  envApiUrl = `https://${process.env.REACT_APP_DOMAIN_PROD}`;
  envMenuUrl = `https://${process.env.REACT_APP_MENU_APP_DOMAIN_PROD}`;
  envPaymentsUrl = `https://${process.env.REACT_APP_PAYMENT_DOMAIN_PROD}`;
  envPromotionsUrl = `https://${process.env.REACT_APP_PROMO_DOMAIN_PROD}`;
  envStripeKey = process.env.REACT_APP_STRIPE_LIVE;
} else if (env === 'staging') {
  envApiUrl = `https://${process.env.REACT_APP_DOMAIN_STAG}`;
  envMenuUrl = `https://${process.env.REACT_APP_MENU_APP_DOMAIN_DEV}`;
  envPaymentsUrl = `https://${process.env.REACT_APP_PAYMENT_DOMAIN_DEV}`;
  envPromotionsUrl = `http://${process.env.REACT_APP_PROMO_DOMAIN_DEV}`;
  envStripeKey = process.env.REACT_APP_STRIPE_DEV;
} else {
  envApiUrl = `https://${process.env.REACT_APP_DOMAIN_DEV}`;
  envMenuUrl = `https://${process.env.REACT_APP_MENU_APP_DOMAIN_DEV}`;
  envPaymentsUrl = `https://${process.env.REACT_APP_PAYMENT_DOMAIN_DEV}`;
  envPromotionsUrl = `https://${process.env.REACT_APP_PROMO_DOMAIN_DEV}`;
  envStripeKey = process.env.REACT_APP_STRIPE_DEV;
}

export const apiUrl = envApiUrl;
export const menuUrl = envMenuUrl;
export const paymentsUrl = envPaymentsUrl;
export const appName = process.env.REACT_APP_NAME;
export const stripeKey = envStripeKey;
export const promotionsUrl = envPromotionsUrl;
export const AWSKey = process.env.REACT_APP_AWS;
export const APG = `https://${process.env.REACT_APP_APG}`;

export const cloudfront = `https://d2b8t2l9x5xm7h.cloudfront.net`;
